<template>
  <el-dialog :title="editType=='add' ? '新增自动回复':editType == 'show' ?'自动回复详情':'编辑自动回复'" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="520px">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="22">
          <el-form-item label="关键字" prop="keyWord">
            <el-input placeholder="最长100位，多个关键字用|分隔" v-model="form.keyWord" maxlength="100" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="22">
          <el-form-item label="回复内容"  prop="replyContent">
            <el-input type="textarea" placeholder="最长500位" v-model="form.replyContent"
            show-word-limit
             maxlength="500" :disabled="editShow" :autosize="{ minRows: 10, maxRows: 10}" resize="none" ></el-input>
          </el-form-item>
        </el-col>  
        <el-col :span="24">
          <el-button @click="dialogClose">关闭</el-button>
          <el-button type="primary" v-debounce="onSubmit" v-if="!editShow">保存</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>

import { addAutoReplyBase, updateAutoReplyBase } from '@/api/index'
export default {
  name: 'autoReplyBaseForm',
  components: {  },
  props: ['value', 'editRow', 'editType'],
  data() {
    return {
      form: {
        id:'',
        keyWord: '',    // 名称
        replyContent: '',
      },
      rules: {
        keyWord: [
          { required: true, message: '关键字不能为空', trigger: 'blur' }
        ],
        replyContent: [
          { required: true, message: '回复内容不能为空', trigger: 'blur' }
        ],
      },

    }
  },
  computed: {
    editShow() {
      return this.editType == 'show'
    }
  },
  created() {
    if (this.editType != 'add')  {
      Object.assign(this.form, this.editRow)
    }
  },
  methods: {
    dialogClose(type) {
      if (type == 1) {
        this.$emit('load')
      }
      this.$emit('input', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      let success = response => {
        if (response.code == 200) {
          this.$message({
            message: response.msg,
            type: 'success'
          });
          this.dialogClose(1);
        } else {
          this.$message.error({
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      }
      if (this.editType == 'edit') {
        updateAutoReplyBase(form).then(success)
      } else {
        addAutoReplyBase(form).then(success)
      }
    }
  },

}
</script>