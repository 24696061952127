<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="关键字"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.isDisable" placeholder="请选择" style="width:100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="有效" value="0"></el-option>
              <el-option label="已禁用" value="1"></el-option>
            </el-select>
        </el-form-item>
        
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="showForm('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" stripe>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="关键词" align="center" prop="keyWord" show-overflow-tooltip></el-table-column>
      <el-table-column label="回复内容" align="center" prop="replyContent" show-overflow-tooltip ></el-table-column>   
      <el-table-column label="创建时间" align="center" prop="createTime" show-overflow-tooltip ></el-table-column>     
      <el-table-column label="状态"  align="center" show-overflow-tooltip>        
        <template slot-scope="scope">
          <span @click="disable(scope.row)" class="blue" :class="{'red':scope.row.isDisable==1}" >{{scope.row.isDisable==0?'有效':'已禁用'}}</span>
        </template>
      </el-table-column>  
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-search" @click="showForm('show',scope.row)">查看</el-button>
          <el-button type="text" icon="el-icon-edit" @click="showForm('edit',scope.row)">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" @click="deleteOne(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>


    <AutoReplyBaseForm v-if="isShowForm" v-model="isShowForm" :editType='editType' :editRow="autoReplyBaseInfo" @load="getList" />

  
      
  </div>
</template>

<script>

import { Confirm } from '@/utils/common.js'
import {   getAutoReplyBasePageList,deleteAutoReplyBase,updateAutoReplyBase } from '@/api/index.js'
import AutoReplyBaseForm from './autoReplyBaseForm.vue'
export default {
  name: 'autoReplyBase',
  components: { AutoReplyBaseForm },
  data() {
    return {
      isShowForm:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        isDisable:''
      },
      list: [],
      total: 0,
      autoReplyBaseInfo:{},
      editType:''
    }
    
  },
  created() {
    this.getList();
  },
  methods: {
    disable(info){
      let msg = info.isDisable==1? '启用':'禁用';
      let isDisable = info.isDisable==1?0:1;
      msg = '您确认'+msg+'吗？';
      Confirm(msg, () => {
        let params = {
          id:info.id,
          isDisable:isDisable
        }
        updateAutoReplyBase(params).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '操作失败')
            return
          }
          this.$message.success('操作成功！')
          this.getList()
        })
      })
    },
    showForm(type,info){
      this.autoReplyBaseInfo = info
      this.editType = type
      this.isShowForm = true;
    },
    getList() {
      getAutoReplyBasePageList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('确定删除该内容吗?', () => {
        deleteAutoReplyBase({ id:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.blue{
  color:blue;
  cursor: pointer;
}
.red{
  color:red;
}

</style>